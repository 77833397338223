
function mergeIntoGlobal(object) {
  for (const key in object) {
    if (key === "default") {
      // Skip default exports
      continue;
    }
    const value = object[key];
    const existingValue = window[key];
    if (existingValue !== undefined) {
      throw new Error(`Property ${key} already exists in global context`);
    }

    window[key] = value;
  }
}

import * as core from "./core/globals";
mergeIntoGlobal(core);

import * as game from "./game";
mergeIntoGlobal(game);