const playerUpdateRate = 30;

export const HandleIntervals = (function () {

  const createInterval = (handler, timeout) => {
    let id = -1;
    return {
      start() {
        if (this.isStarted) {
          // throw new Error('Interval already started');
        } else {
          id = setInterval(handler, timeout)
        }
      },
      get isStarted() {
        return id !== -1;
      },
      stop() {
        clearInterval(id);
        id = -1;
      },
      restart() {
        this.stop();
        this.start();
      }
    }
  }

  return {
    all() {
      return Object.values(HandleIntervals)
        .filter(interval => Object.prototype.hasOwnProperty.call(interval, 'start') && Object.prototype.hasOwnProperty.call(interval, 'stop'))
    },
    start() {
      this.all().forEach(interval => interval.start());
    },
    stop() {
      this.all().forEach(interval => interval.stop());
    },
    restart() {
      this.all().forEach(interval => interval.restart());
    },
    gameLoop: createInterval(() => gameLoop(), playerUpdateRate)
  }
}())