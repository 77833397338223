import { cn } from './util/cn'
import './App.css'

import './util/utils'
import './merge-globals'

import { useEffect } from 'react'
import { HandleIntervals } from './core/intervals'

// CRP === Cybernetic Research Points
// unlocks base upgrades
// hire researches to perform tasks

// TODO: make configurable by player
const enableGlitch = true

function App() {
  useEffect(() => {
    HandleIntervals.start();
  }, [])

  return (
    <div className="bg-black h-screen flex flex-col place-items-center overflow-hidden bg-[url('/public/bg02.png')] bg-repeat-x bg-[length:1024px]">
      <h1
        className={cn(
          'glitch-text',
          enableGlitch ? 'glitch-text-animation' : ''
        )}
      >
        SYNAPSE
      </h1>
      <div className="flex flex-col place-content-center h-screen">
        <div>
          <button
            className="text-white"
            onClick={() => {
              player.currency += 1
            }}
          >
            Purchase -
          </button>
        </div>
        <progress
          className="text-white"
          max={100}
        ></progress>
        <div className="text-white">{player.currency}</div>
      </div>
    </div>
  )
}

export default App
